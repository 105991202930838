import './Main.css';
import Footer from "../Footer/Footer";
import About from "../About/About";
import Projects from "../Projects/Projects";
import Skills from "../Skills/Skills";

const Main = ({activeTab}) => {
	return (
		<main className="main">
			{ activeTab === "about" ? <About/> : ""}
			{ activeTab === "projects" ? <Projects/> : ""}
			{ activeTab === "skills" ? <Skills/> : ""}
			<Footer/>
		</main>
	);
};

export default Main;
