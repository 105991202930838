import './Projects.css';
import hexagame from '../../images/hexagame.jpg';
import youtube from '../../images/youtube.jpg';
import spo44 from '../../images/spo44.jpg';
import ksmolnia from '../../images/molnia.jpg';

function Projects() {
  return (
    <section className="projects">

            <div className="projects__item">
                <a className="projects__link" href="https://www.youtube.com/@S0lved" target="_blank" rel="noreferrer">
                    <div className="projects__item-sticker">
                        Проект: YouTube канал <br/>
                        Образовательный контент <br/>
                        Программирование сайтов и приложений <br/>
                        Разные языки программирования и задачи <br/>
                    </div>
                    <div className="projects__item-view">
                        <img loading="lazy" className="projects__item-image" src={youtube} alt=""/>
                        <h3 className="projects__item-heading">YouTube</h3>
                    </div>
                        <div className="projects__item-description">
                    </div>
                </a>
            </div>

        <div className="projects__item">
            <a className="projects__link" href="https://spo44.ru/" target="_blank" rel="noreferrer">
                <div className="projects__item-sticker">
                    Проект: Спецотделка 44 <br/>
                    Сайт строительной организации <br/>
                    Автоматический пересчет цен домов <br/>
                    при изменении стоимости материалов
                </div>
                <div className="projects__item-view">
                    <img loading="lazy" className="projects__item-image" src={spo44} alt=""/>
                    <h3 className="projects__item-heading">Spo44.ru</h3>
                </div>
                <div className="projects__item-description">

                </div>
            </a>
        </div>
        <div className="projects__item">
            <a className="projects__link" href="https://www.ksmolnia.ru/" target="_blank" rel="noreferrer">
                <div className="projects__item-sticker">
                    Проект: KS Молния <br/>
                    Сайт службы доставки <br/>
                    Разные интерфейсы для сотрудников <br/>
                    Для внутреннего пользования
                </div>
                <div className="projects__item-view">
                    <img loading="lazy" className="projects__item-image" src={ksmolnia} alt=""/>
                    <h3 className="projects__item-heading">Ksmolnia.ru</h3>
                </div>
                <div className="projects__item-description">

                </div>
            </a>
        </div>
        <div className="projects__item">
            <a className="projects__link" href="https://hexagame.ru/" target="_blank" rel="noreferrer">
                <div className="projects__item-sticker">
                    Проект: Hexagame <br/>
                    Игра - головоломка, написанная на Flutter <br/>
                    Формат одного приложения для разных платформ: <br/>
                    Android, IOS, Web, MasOS, Windows, Linux <br/>
                    Доступна в Google Play и на hexagame.ru
                </div>
                <div className="projects__item-view">
                    <img loading="lazy" className="projects__item-image" src={hexagame} alt=""/>
                    <h3 className="projects__item-heading">Hexagame</h3>
                </div>
                <div className="projects__item-description">

                </div>
            </a>
        </div>
        <div className="projects__item">
            <a href="https://t.me/artyom_chernov" target="_blank" rel="noreferrer">
                <div className="projects__item-view">
                    <span className="projects__item-image">
                        <svg width="80px" height="80px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 12H20M12 4V20" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                </div>
            </a>
        </div>
    </section>
  );
}

export default Projects;
